<template>
  <div class="cooperation">
    <Head />
    <TitleBg :flag="showTitle" />
    <div class="main">
      <div class="doing-what">商务合作</div>
      <div class="help-content">
        <div class="help-box">
          <ul>
            <li>
              <div class="top">
                <div class="title">商务合作</div>
                <div class="jiantou" />
              </div>
              <div class="bottom problem">
                <div class="p_content">
                  <p class="p_line">-</p>
                  <p class="p_title">合作介绍</p>
                  <p class="p_icon">
                    上海域起网络科技有限公司成立于 2013年，始终秉承“通过科技和艺术改变世界”的文化使命，是一家集游戏研发和发行于一体的高新技术企业，2020年被评定为浦东新区企业研发机构、浦东新区高成长性总部，并在2021年成为上海市第一批重点机构中的一员。
                  </p>
                  <p class="p_icon">
                    公司拥有5A级办公环境，积极推崇硅谷文化。公司规模近400人，核心成员曾服务于华为、腾讯、网易、莉莉丝等多家知名企业。公司自主研发并发行了《GG游戏大厅》、《Garden Affairs》等多款游戏，并于近年分别独家代理了以二次元“东方Project”为原型的二次创作手游《东方LostWord》、由国内知名团队研发的Match-3手游《Home Makeover》等，在全球拥有上亿注册用户。
                  </p>
                  <p class="p_icon">
                    通过自主研发、发行与代理发行并行，域起网络未来将开拓更多领域，进一步完善公司产业线布局，立足全球化视野，开拓产业发展战略新步伐。
                  </p>
                </div>
                <!-- <div class="p_content left">
                  <p class="p_line">-</p>
                  <p class="p_title">商务合作</p>
                  <p class="p_icon">
                    欢迎海内外各移动游戏研发公司及工作室洽谈合作。
                  </p>
                  <p class="min_title">联系方式</p>
                  <p class="p_icon">
                    Email： business@gg.com / shichang@gg.com
                  </p>
                  <p class="p_icon">微信1：gg-technology</p>
                  <p class="p_icon">微信2：vicky-luhm</p>
                </div> -->
                <!-- <div class="p_content left media">
                  <p class="p_title">媒体合作</p>
                  <p class="p_icon">
                    欢迎各大媒体网站合作交流。
                  </p>
                  <p class="min_title">联系方式</p>
                  <p class="p_icon">
                    Email： marketing@gg.com / shichang@gg.com
                  </p>
                  <p class="p_icon">QQ：1625043997（媒介）</p>
                  <p class="p_icon">QQ：14356</p>
                </div> -->
                <div class="p_content">
                  <p class="p_line">-</p>
                  <p class="p_title">联系我们：</p>
                  <!-- <p class="p_icon">欢迎各跨界大企业合作交流</p> -->
                  <p class="p_icon">电话：400-9031-158 </p>
                  <p class="p_icon">邮件：business@gg.com</p>
                  <img src="@/assets/image/pc/home/map.png" alt="">
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Floor />
  </div>
</template>
<script>
import Head from '@/views/Head'
import Floor from '@/views/Floor'
import TitleBg from '@/views/NewsDetails/TitleBg'

export default ({
  name: "Cooperation",
  components: {
    Head,
    Floor,
    TitleBg
  },
  data() {
    return {
      showTitle: false
    }
  }
})
</script>

<style lang="scss" scoped>
body {
  background-color: #fff;
  /* font-size: 1*2rem; */
}

.help {
  color: #e62631 !important;
}

.big_logo .bgi {
  width: 100%;
}

.doing-what {
  border-left: 0.125*2rem solid #3a79d4;
  font-size: 0.6875*2rem;
  padding-left: 0.6125*2rem;
  line-height: 0.6875*2rem;
  margin: 0.625*2rem 10.3125*2rem;
}

.help-content {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.help-content .help-box {
  margin: 1.25*2rem 0;
  overflow: hidden;
  width: 35.625*2rem;
  background-color: #fff;
  padding: 0.8125*2rem;
  box-sizing: border-box;
  border-radius: 0.1875*2rem;
  border: 0.0625*2rem solid #eee;
}

.help-content .help-box li {
  background-color: #f5f5f5;
  border-radius: 0.1875*2rem;
  font-size: 0.5625*2rem;
  margin-bottom: 0.625*2rem;
}

.help-content .help-box li .top {
  width: 33.6875*2rem;
  height: 2.0625*2rem;
  padding: 0 1.25*2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.help-content .help-box li:last-of-type {
  margin-bottom: 0;
}

.help-content .help-box li .jiantou {
  width: 0.3125*2rem;
  height: 0.3125*2rem;
  border-left: 0.0625*2rem solid #ccc;
  border-bottom: 0.0625*2rem solid #ccc;
  transform: rotate(-45deg);
}

.help-content .help-box li:last-of-type .jiantou {
  transform: rotate(-45deg);
}

.help-content .help-box li .problem {
  padding: 0 1.25*2rem 0.625*2rem 1.25*2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.help-content .help-box li .problem .topic {
  color: red;
  margin-top: 0.625*2rem;
}

.help-content .help-box li .problem .content {
  padding: 0.625*2rem;
  margin-top: 0.625*2rem;
  background-color: #f5f5f5;
  border-radius: 0.1875*2rem;
  border: 0.0325*2rem solid #e7e7e7;
  font-size: 0.4375*2rem;
  display: flex;
  flex-direction: column;
  vertical-align: top;
}

.help-content .help-box li .problem .content span:not(:last-of-type) {
  height: 1.25*2rem;
}

.help-content .help-box li .bottom {
  border-top: 0.0325*2rem solid #eee;
  display: block;
}

.new-help {
  text-align: center;
  padding-bottom: 1.25*2rem;
}

.new-help > div {
  margin-top: 0.3125*2rem;
  line-height: 1.25*2rem;
}

.new-help h4 {
  font-weight: 700;
}

.new-help .rz img {
  width: 11.375*2rem;
}

.new-help .install img {
  width: 3.5625*2rem;
}

.problem p {
  cursor: pointer;
}

.problem .bottomic {
  display: none;
}
.p_con,
.p_join {
  line-height: 1.25*2rem;
  font-size: 0.5*2rem;
  text-align: center;
}

.p_content .p_title {
  font-size: 0.5587*2rem;
  font-weight: 700;
  line-height: 1.5*2rem;
}
.p_content .p_line {
  font-size: 1*2rem;
  width: 0.625*2rem;
  color: #c4222e;
}
.p_content .p_icon {
  text-indent: 2em;
  line-height: 1.2*2rem;
  font-size: 0.4375*2rem;
}
.p_content .min_title {
  font-size: 0.4688*2rem;
  font-weight: 700;
}
.left {
  float: left;
}
.media{
  margin-left: 3.125*2rem;
  margin-top: 1.25*2rem;
}
.p_content img{
  width: 100%;
}
</style>
